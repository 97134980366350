.description-markup p, .description-markup span, .description-markup h2 {
  font-family: "ABC Favorit Book";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px; 
  font-weight: 400;
}
.description-markup ul {
  margin-left: 40px;
  list-style: disc;
}

.description-markup a {
  color: black;
}

