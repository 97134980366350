.policy-markup h3 {
  font-family: "Nib Pro";
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 48px;
    line-height: 48px;
}

.policy-markup p, .policy-markup ul, .policy-markup ol {
  font-family: "ABC Favorit Book";
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  margin-bottom: 10px;
}
.policy-markup ul {
  list-style-type: disc;
    padding-left: 20px;
}


ul, ol, u {
  font-family: "ABC Favorit Book";
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.policy-markup strong {
  font-family: "ABC Favorit Book";
  font-size: 20px;
}


  th, td {
    border: 1px solid black !important; /* Add borders to individual cells */
    padding:.5rem !important;
  }