

@font-face {
	font-family: "ABC Favorit Book Italic";
	src: local('sans-serif'),  url("./assets/fonts/favorit/ABCFavorit-BookItalic.otf") format("opentype"),
	 url("./assets/fonts/favorit/ABCFavorit-BookItalic.ttf") format("truetype"),
	  url("./assets/fonts/favorit/ABCFavorit-BookItalic.woff") format("woff"),
	  url("./assets/fonts/favorit/ABCFavorit-BookItalic.woff2") format("woff2");
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "ABC Favorit Book";
	src: local('sans-serif'), url("./assets/fonts/favorit/ABCFavorit-Book.otf") format("opentype"),
	url("./assets/fonts/favorit/ABCFavorit-Book.ttf") format("truetype"),
	  url("./assets/fonts/favorit/ABCFavorit-Book.woff") format("woff"),
	  url("./assets/fonts/favorit/ABCFavorit-Book.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;	
	font-display: swap;
}
@font-face {
	font-family: "ABC Favorit";
	src: local('sans-serif'), url("./assets/fonts/favorit/ABCFavorit-Medium.otf") format("opentype"),
	url("./assets/fonts/favorit/ABCFavorit-Medium.ttf") format("truetype"),
	 url("./assets/fonts/favorit/ABCFavorit-Medium.woff") format("woff"), 
	 url("./assets/fonts/favorit/ABCFavorit-Medium.woff2") format("woff2"); 
	 font-weight: 500;
	 font-display: swap;
}

@font-face {
	font-family: "ABC Favorit Light"; 
	src: local('sans-serif'), url("./assets/fonts/favorit/FavoritLight.otf") format("opentype"), 
	url("./assets/fonts/favorit/FavoritLight.ttf") format("truetype"), 
	url("./assets/fonts/favorit/FavoritLight.woff") format("woff"), 
	url("./assets/fonts/favorit/FavoritLight.woff2") format("woff2");
	font-weight:300; 
	font-display: swap;
}

@font-face {
	font-family: "ABC Favorit Lining";
	src: local('sans-serif'),  url("./assets/fonts/favorit/ABCFavoritLining-LightItalic.otf") format("opentype"),
	url("./assets/fonts/favorit/ABCFavoritLining-LightItalic.ttf") format("truetype"),
	  url("./assets/fonts/favorit/ABCFavoritLining-LightItalic.woff") format("woff"), 
	  url("./assets/fonts/favorit/ABCFavoritLining-LightItalic.woff2") format("woff2"); 
		font-display: swap;
		font-weight: 400;
}

@font-face {
	font-family: "Nib Pro";
	src: local('sans-serif'),  url("./assets/fonts/nib/nib-italic-pro.eot"); 
	src: local('sans-serif'), url('./assets/fonts/nib/nib-italic-pro.eot?#iefix') format('embedded-opentype'),
	 url("./assets/fonts/nib/nib-italic-pro.ttf") format("truetype"),
	 url("./assets/fonts/nib/nib-italic-pro.otf") format("opentype"),
	  url("./assets/fonts/nib/nib-italic-pro.woff") format("woff"), 
	  url("./assets/fonts/nib/nib-italic-pro.woff2") format("woff2"); 
	font-weight: 400; 
	font-style: normal; 
	font-display: swap;
}
@font-face {
	font-family: "NeueBit";
	src: local('sans-serif'),  url("./assets/fonts/nib/NeueBit-Bold.eot") ; 
	src: local('sans-serif'), url("./assets/fonts/nib/NeueBit-Bold.eot?#iefix") format('embedded-opentype'),
	  url("./assets/fonts/nib/NeueBit-Bold.otf") format("opentype"),
	  url("./assets/fonts/nib/NeueBit-Bold.woff") format("woff"), 
	  url("./assets/fonts/nib/NeueBit-Bold.woff2") format("woff2"), 
	  url("./assets/fonts/nib/NeueBit-Bold.ttf")  format("truetype"); 
		font-display: swap;

}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	font-family: "ABC Favorit Book", Arial, Helvetica, sans-serif; 
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;

}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	background: #E5E5E5;
	background-color: #E5E5E5;
	transition: background-color .5s ease-in; 
	min-height: 100vh;
	min-height: -webkit-fill-available;
 }
 html {
	height: -webkit-fill-available; 
	background: #E5E5E5;
	scroll-behavior: smooth;
 }

