
#nevadaHero{
    background: linear-gradient(180deg, rgba(229, 229, 229, 0.00) 0%, rgba(229, 229, 229, 0.86) 72.4%, #E5E5E5 100%), url('../../assets/nevada/nevada-hero.jpg');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
}


#nevadaPage .productSize {

    display: none;

}

#nevadaPage .blankLogo::after{
  content: "";
  display: block;
  border-bottom: 3px solid black;
  margin-top: -0.75rem; 
}



#nevadaPage .productSize + div  {

   margin-top:1rem;

}
@media (max-width: 768px) {

    #nevadaHero{

        background: linear-gradient(180deg, rgba(229, 229, 229, 0.00) 0%, rgba(229, 229, 229, 0.3) 72.4%, #E5E5E5 100%), url('../../assets/nevada/nevada-hero.jpg');
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
    }

}