@media only screen and (max-width: 350px) {
  .create-your-own-color-btn button {
    font-size: 10px;
  }
  .product-page-container {
    padding: 24px 20px; 
  }
  .shopify-background-container {
    max-height: 225px;
  }
  .ico-bottle-container {
    height: 230px; 
  }
}
@media only screen and (min-width: 351px) {
  .create-your-own-color-btn button {
    font-size: 14px;
  }
  .product-page-container {
    padding: 30px 20px; 
  }
  /* .shopify-background-container {
    max-height: 270px;
  } */
  .ico-bottle-container {
    height: 275px; 
  }
}
