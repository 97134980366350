
@media only screen and (max-width: 350px) {
  .expand-panel-container {
    width: 252px; 
  }
  .expand-panel-container p {
    font-size: 13px;
  }
}

@media only screen and (min-width:351px) {
  .expand-panel-container {
    width: 315px;
  }
  .expand-panel-container p {
    font-size: 14.5px;
  }
}