/* Header Styles */

.logo-wrap {
  width: 100px;
  margin-bottom: -2px;
  position: relative;
}
.logo-wrap:after,
.logo-wrap:before {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  height: 2px;
  background: #000;
}
.logo-wrap:after {
  width: 1000%;
  right: 100%;
}
.logo-wrap:before {
  width: 10000%;
}

.blankLogo::after{
  content: "";
  display: block;
  border-bottom: 3px solid black;
  margin-top: -0.75rem; 
}


body.nav-open .nav-toggle .icon:before {
  -webkit-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}



body.nav-open .nav-toggle .icon:after {

  -webkit-transform: translateY(-5px) rotate(-45deg);
  -ms-transform: translateY(-5px) rotate(-45deg);
  transform: translateY(-5px) rotate(-45deg);
}


body.nav-open .nav-toggle {
  background-color: transparent; 
  height: 0px; 
}

.nav-toggle {
  width: 100%; 
  height: 2px; 
  position: relative; 
  background-color: white;
  transition: all .35s ease-in-out; 
  -webkit-transition: all .35s ease-in-out;
  -webkit-transition-timing-function: swing;
  transition-timing-function: swing;
  cursor: pointer;
}
.nav-toggle:before {
  content: "";
  position: absolute; 
  top: -7px; 
  height: 2px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition-duration: 0.66s;
  transition-duration: 0.66s;
  -webkit-transition-timing-function: swing;
  transition-timing-function: swing;
}

.nav-toggle .icon {
  width: 100%;
  height: 0px;
  background-color: white;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  transition-timing-function: swing;
}

.nav-toggle .icon:before,
.nav-toggle .icon:after {
  width: 100%;
  height: 2px;
  background-color: white;
  content: "";
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  transition-timing-function: swing;
}

.nav-toggle .icon:before {
  top: -7px;
}

.nav-toggle .icon:after {
  bottom: -7px;
}

/* end Header Styles */