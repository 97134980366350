.hero-image 
  {
    background-image: -webkit-image-set(
      url("../../../assets/heroAssets/heroBottles.png") type("image/png"),
      url("../../../assets/heroAssets/heroBottles.webp") type("image/webp"));
    background-image: image-set(
      url("../../../assets/heroAssets/heroBottles.png") type("image/png"),
      url("../../../assets/heroAssets/heroBottles.webp") type("image/webp"));
  }


.btn {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: white;
  border: 2px solid black;
  font-size: 20px;
  line-height: 22px;
  border-radius: 60px;
  padding: 12px 47px;
  font-family: "ABC Favorit";
  text-align: center;
  color: black;
  min-width: 200px;
}
.btn:hover {
  background-color: black;
  color: white;
}

.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-flow: row wrap;
}

/* img {
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  border-style: none;
  -webkit-perspective: 0;
  perspective: 0;
  -webkit-backface-visibility: hidden;
  transform: translate3d(0,0,0);
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
  backface-visibility: hidden;
} */

/* Start Hero Style */
/* .bg-change {
  transition: background-color 1s ease-in; 
} */
.red {
  background-color: #fc0050;
}
.blue {
  background-color: #00C0F4;
}
.purple {
  background-color: #C35CFF; 
}
.orange {
  background-color: #FF8B20; 
}
.green {
  background-color: #C6EA00; 
}
.hero-wrap {
  float: left;
  width: 100%;
  padding: 0 0 100px;
  overflow: hidden;
}
.hero-inner {
  padding-left: 95px;
  position: relative;
  z-index: 1;
}
.hero-content-wrap {
  width: 52.09486166007905%;
  padding-top: 153px;
}
.hero-content-text-thumb {
  width: 100%;
  margin-bottom: 50px;
}
.hero-content-text-thumb img {
  width: 100%;
}
.hero-content-wrap p {
  font-size: 32px;
  line-height: 42px;
  
  max-width: 550px;
}
.hero-btn {
  margin-top: 31px;
}

.hero-thumb-wrap {
  width: 47.90513833992095%;
  position: relative;
  padding-right: 8.5%;
  justify-content: center;
  align-items: center;
}

.hero-shape-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 107%;
  left: -15%;
  height: 100%;
}
.hero-shape-big {
  float: left;
  width: 100%;
  margin-top: -180px;
  /* height: 100%; */
}

.hero-shape {
  position: absolute;
  max-width: 135px;
  bottom: 0;
}
.hero-shape.hero-shape-one {
  width: 20%;
  top: 0;
  left: 14%;
  bottom: auto;
}
.hero-shape.hero-shape-two {
  width: 30%;
  top: 21%;
  left: 24.3%;
  max-width: 186px;
  bottom: auto;
}
.hero-shape.hero-shape-three {
  width: 20%;
  top: 35%;
  left: -7.5%;
  bottom: auto;
}
.hero-shape.hero-shape-four {
  width: 30%;
  bottom: 5.5%;
  right: -13.4%;
  max-width: 186px;
}
.scale-up {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.hero-thumb {
  max-width: 200px;
  width: 60%;
  position: relative;
  display: none;
}
.reveal {
  visibility: hidden;
  position: relative;
  overflow: hidden;
}
.reveal figure img {
  /* transform: translateY(100%); */
  opacity: 0;
}
.hero-thumb-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.hero-thumb figure {
  width: 100%;
}
.hero-thumb figure img {
  width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform-origin: bottom;
}

.hero-thumb > img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 14%;
  z-index: 1;
}

.hero-thumb-content {
  position: absolute;
  top: 17px;
  right: 0px;
  width: 100%;
  max-width: 262px;
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  cursor: pointer;
  z-index: 2;
}
.hero-thumb-content figure {
  float: left;
  width: 100%;
  position: relative;
}
.hero-thumb-content figure img {
  float: left;
  width: 100%;
}
.hero-thumb-content-text {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 10% 17%;
  text-align: center;
  z-index: 1;
  top: 50%;
  transform: translateY(-48%);
  cursor: pointer;
}
.hero-thumb-content-text h4 {
  margin-bottom: 15px;
  color: black;
  cursor: pointer;
}
.hero-thumb-content-text p {
  font-family: "ABC Favorit";
  color: black;
  cursor: pointer;
}
.hero-thumb-content .small-green-btn {
  position: absolute;
  right: 21%;
  bottom: -20px;
  width: 77px;
  border-radius: 50%;
  z-index: 6;
}
.spin-hover:hover {
  -webkit-animation: rotate-in-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: rotate-in-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.spin-hover {
  -webkit-animation: rotate-out-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: rotate-out-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.hero-thumb-content .small-green-btn img {
  width: 100%;
}
.hero-thumb-btn {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 5;
  display: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}
.fold-scrolled .hero-thumb-btn {
  opacity: 1;
}
.hero-thumb-btn a.btn {
  font-size: 16px;
  padding: 2px 13px;
}
/* End Hero Style *//* Start Hero Style */
.hero-wrap {
  float: left;
  width: 100%;
  padding: 0 0 100px;
  overflow: hidden;
}
.hero-inner {
  padding-left: 95px;
  position: relative;
  z-index: 1;
}
.hero-content-wrap {
  width: 52.09486166007905%;
  padding-top: 153px;
}
.hero-content-text-thumb {
  width: 100%;
  margin-bottom: 50px;
}
.hero-content-text-thumb img {
  width: 100%;
}
.hero-content-wrap p {
  font-size: 32px;
  line-height: 42px;
  font-family: "ABC Favorit Lining";
  max-width: 550px;
}
.hero-btn {
  margin-top: 31px;
}

.hero-thumb-wrap {
  width: 47.90513833992095%;
  position: relative;
  padding-right: 8.5%;
  justify-content: center;
  align-items: center;
}

.reveal img {
  /* transform: translateY(100%); */
  opacity: 0;
}


.common-wrap {
  max-width: 1400px;
  margin: 0 auto;
  clear: both;
  padding: 0 20px;
}
.clear:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
/* .css-1ak34d2 {
  display: none;
} */
.css-arch06 {
  display: block;
}

@-webkit-keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(180deg) scale(1.1);
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
}
@keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(180deg) scale(1.1);
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
}
@-webkit-keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  100% {
    -webkit-transform: rotate(180deg) scale(1.1);
    transform: rotate(180deg) scale(1.1);
  }
}
@keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  100% {
    -webkit-transform: rotate(180deg) scale(1.1);
    transform: rotate(180deg) scale(1.1);
  }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .hero-shape-wrap {
    width: 100vw; 
    left: 50%; 
    transform: translate(-50%); 
    height: 100%; 
  }
}


@media only screen and (max-width: 480px) {
  .hero-shape-big {
    width: 100%;
    position: absolute;
    top: 0; 
    transform: translate(-50%) rotate(-5deg);
    max-height: 150%;
    height: 120%; 
    margin-top: 20%; 
  }
 }
@media (max-width: 480px) {
  .hero-shape-big {
    height: 122%; 
    margin-top: -54px;
  }
 }




@media only screen and (min-device-width: 320px) and (max-device-width: 480px){
.hero-shape.hero-shape-one {
    display: none;
}
}

@media (max-width: 480px) {
.hero-shape.hero-shape-two {
    width: 33.07%;
    top: 11%;
}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
.hero-shape.hero-shape-two {
    width: 32%;
    top: 12%;
    left: 15.3%;
}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 479px) {
  .hero-shape.hero-shape-three {
      width: 18%;
     
      left: 5.5%;
      bottom: 7%;
  }
  }
@media (max-device-width: 480px) {
.hero-shape.hero-shape-three {
    width: 37.602%;
    left: -7.5%;
    bottom: -3%;
    /* top: auto; */
}
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
.hero-shape.hero-shape-four {
    width: 34%;
    /* bottom: -4.5%; */
    right: -14.4%;
}
}
@media (max-width: 480px) {
.hero-shape.hero-shape-four {
    width: 44%;
    bottom: 11.5%;
    right: -21.4%;
}
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
.hero-thumb {
    max-width: 140px;
}
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .css-1ak34d2 {
    display: block;
  }
  .hero-thumb-content {
    max-width: 130px;
    top: -20px;
    right: -14px;
  }
  .hero-thumb-content-text {
    padding: 5px 10%;
    transform: translateY(-50%);
    top: 36%; 
    left: 11%;  
  }
  .hero-thumb-content-text h4 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .hero-thumb-content-text p {
    font-size: 12px;
    line-height: 12px;
  }
  
  .hero-thumb-content .small-green-btn {
    right: -4%;
    bottom: 65px;
    width: 48px;
  }
  .hero-thumb {
    max-width: 140px;
  }
  .hero-thumb-btn {
    display: none;
  }
  .css-arch06 {
    display: none;
}

}


@media only screen and (max-width: 350px) {
  .hero-phone-main-container {
    top: -120px;
  }

  .video-phone-container {
    width: 275px;
  }

  .video-container {
    max-height: 405px;
  }

  .video-phone-pre-screen {
    top: 38px; 
    left: 43px;
    max-height: 390px;
    max-width: 188px;
  }

  .autoPlay-container {
    width: 180px;
  }

  .auto-play-video {
      height: 390px;
  }

  .your-inspo-container {
    height: 50%; 
  }
}

   @media only screen and (min-width:351px) {
    .hero-phone-main-container {
      top: -100px
    }
    .video-phone-container {
     width: 325px;
    }
    .video-container {
      max-height: 461px;
    }
    .video-phone-pre-screen {
      top: 38px; 
     left: 52px;
     max-height: 460px;
     max-width: 220px;
 }
 .autoPlay-container {
  width: 220px;
 }
 .auto-play-video {
  height: 461px;
}

.your-inspo-container {
  height: 45%; 
}
    }

/* End Hero Style */