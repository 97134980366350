.disable-text-selection {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -html-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
}
.canvas {
  border-radius: 15px;
}


.suggested-colors-container{

display: grid;
grid-template-areas:
'col1  col2  col3  col4  col5 col6'
'. col7  col8  col9  col10 .';
gap:1rem;
width:100%;
justify-content:center;

}
.color-grid-item{
  border-radius:50%;
  height:2rem;
  width:2rem;
  display:flex;
  justify-content:center;
  align-items: center;
}


.active-color-grid-item{
  border:2px solid black;
  height:3rem;
  width:3rem;
  border-radius:50%;

  display:flex;
  justify-content:center;
  align-items: center;
}

.kiosk-confirm-container{
  display:flex;
  justify-content:center;
  gap:1rem;
  align-items:center;
  margin:auto 0;
  width:100%;
}
.confirm-container{
  display:flex;
  justify-content:center;
  gap:1rem;
  align-items:center;
  margin:auto 0;
}

.suggested-colors-container > div{
  display:flex;
  align-items:center;
  justify-content:center;
}

.suggested-colors-container > div:nth-child(7) { grid-area: col7; grid-column-start: 2; grid-column-end: 3; }
.suggested-colors-container > div:nth-child(8) { grid-area: col8; grid-column-start: 3; grid-column-end: 4; }
.suggested-colors-container > div:nth-child(9) { grid-area: col9; grid-column-start: 4; grid-column-end: 5; }
.suggested-colors-container > div:nth-child(10) { grid-area: col10; grid-column-start: 5; grid-column-end: 6; }

